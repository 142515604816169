/**
 * Calm theme for the jasmine-bloom StyledHtmlReporter output.
 *
 * This theme does away with the default boxey look. The resulting report
 * is a lot less "busy" thus making it easy to focus on your specs.
 */
 
/* jasmine-reporter style overrides for */
.jasmine_reporter a { text-decoration:none; }

.jasmine_reporter > .suite {
    padding-bottom: 0.3em;
    margin-bottom: 0.3em;
    border-bottom: solid 2px #eee; }

.banner, .runner {
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px; }

.runner {
    -webkit-box-shadow: 2px 2px 3px #888;
    -moz-box-shadow: 2px 2px 3px #888;
    box-shadow: 2px 2px 5px #888; }

.suite {
    border: none;
    padding-left: 0.5em; }
    .suite.failed, 
    .suite.passed, 
    .suite.skipped {
        background: transparent; }
    .suite.passed > a {
        color: green; }
    .suite.failed > a {
        color: #c11b17; }
    .suite.skipped > a {
        color: #aaa; }
    .suite > .description {
        font-weight: bold; }
    .suite.step > .description {
        font-weight: normal; }

.spec {
    margin: 0px;
    border: none;
    padding-left: 0.5em;
    margin-left: 0.5em;
    margin-top: 0.2em; }
    .spec.failed, 
    .spec.passed, 
    .spec.skipped {
        background: transparent;
        border: none;
        padding-bottom: 0.2em; }
    .spec.failed a {
        color: #c11b17; }
    .spec.passed a {
        color: green; }
    .spec.skipped a {
        color: #ccc; }
    .spec:hover {
        background-color: #eee;
        -webkit-border-radius: 7px;
        -moz-border-radius: 7px;
        border-radius: 7px; }
    .spec .description {
        padding-left: 0.5em; }

.messages {
    border: none;
    margin-left: 1em;
    padding-bottom: 0.5em; }

.jasmine_reporter .summary ul {
    font-size: 0.9em;
    color: #333;
    padding-left: 0.2em;
    margin: 0 0.5em; }

.jasmine_reporter .summary li {
    list-style: none; }

.jasmine_reporter .details ul {
    font-size: 0.8em;
    color: #777;
    padding-left: 0.2em;
    margin: 0 0.8em; }

.jasmine_reporter .details li {
    list-style: none; }


/* header style (if header is used) */
.header {
    margin: 0 5px; }
    .header h1 {
        font-size: 1.3em; }
    .header ul.menu {
        margin: 0 0 0.5em 0; 
        padding: 0.3em;
        background-color: #eee; }
    .header ul.menu li {
        display: inline; 
        list-style-type: none; 
        margin-right: 1em; }